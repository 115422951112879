import React, {useEffect,useState, useReducer, useRef } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/DatasetProfile/Body/Body';
import {  handleShareClick, setInitialState, getUserRoles, getDispFields, removeUrlQueryArg } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton';
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr';
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import 'url-search-params-polyfill';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkLineageLinkable, checkProfileEditable, checkTermLinkable } from '../../permissionChecker';
import { lineageObjectsMap } from '../../components/UI/Lineage/utils';
import useAlert from '../../hooks/useAlert';
import AddToCartButton from '../../components/UI/Buttons/AddToCartButton';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';


const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  normalText: {
    color: theme.palette.primaryText.main,
  }
});

const initialState = {
  tabState:0,
  noteTabState:0,
  noteDetailMap:{},
  usagePeriod:3,
  showDeletedContent:false,
  usageType:'indirect',  
  childSearch:'',
  childSort:'total_usage_srt desc',
  lineageData:{
    tabState:0,
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['TABLE','COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY'],
    downstreamObjects:['TABLE','COLUMN','CONTENT','CONTENT_CHILD','DATASET','DATA_PIPELINE','FILE','QUERY'],
    
    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_child_count':
      return {
        ...state,
        childCount:action.childCount
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_child_data':
      return {
        ...state,
        childListData:action.childListData,
        childListLoading:action.childListLoading,
        childListError:action.childListError
      }
    case 'set_show_reference_child':
      return {
        ...state,
        showReferenceChild:action.showReferenceChild
      }
    case 'set_show_deleted_child':
      return {
        ...state,
        showDeletedChild:action.showDeletedChild
      }
    case 'set_child_sort':
      return {
        ...state,
        childSort:action.childSort
      }
    case 'set_child_search':
      return {
        ...state,
        childSearch:action.childSearch
      }
    case 'set_dq_insights_data':
      return {
        ...state,
        dqInsightsData:action.dqInsightsData
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_changes':
      return {
        ...state,
        changes:action.changes,
        changesLoading:action.changesLoading,
        changesError:action.changesError
      }
    case 'set_change_date':
      return {
        ...state,
        changeDate:action.changeDate
      }
    case 'set_change_type':
      return {
        ...state,
        changeType:action.changeType
      }
    case 'set_selected_change':
      return {
        ...state,
        selectedChange:action.selectedChange
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData:action.insightsData
      }
    case 'set_link_collection_modal_open':
      return {
        ...state,
        linkCollectionModalOpen:action.linkCollectionModalOpen
      }
    case 'set_collection_instances':
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    case 'set_selected_date':
      return {
        ...state,
        selectedDate:action.selectedDate
      }
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueError: action.issueError,
        issueLoading: action.issueLoading
      }
    case 'set_dqModal_open':
      return {
        ...state,
        dqModalOpen: action.dqModalOpen
      }
    case 'set_lineage_data':
      return {
        ...state,
        lineageData:action.lineageData
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen:action.additionalPropertiesModalOpen,
      }
    case 'set_is_steward_or_owner':{
      return {
        ...state, isStewardOrOwner:action.isStewardOrOwner
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const DatasetProfile = props => {
  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache
  } = props;


  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');
  let startState = setInitialState(pageCache,initialState);
  const roles = getUserRoles(sessionData.user_role)

  const [state, dispatch] = useReducer(reducer,startState);
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const getTabOptions = (childCount, data) => {
    return [childCount?'TABLES':undefined,'DETAILS','KNOWLEDGE',roles.every(el=>el==='90') || data.usage_txt==='UNKNOWN'?undefined:'USAGE','LINEAGE','ISSUES','QUALITY','CHANGES'].filter(el=>el!==undefined);
  }

  let isLineageLinkable = checkLineageLinkable({sessionData, isStewardOrOwner: state.isStewardOrOwner})

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    if(tabName && state.basicData && getTabOptions(state.childCount, state.basicData).includes(tabName.toUpperCase()) && !isNaN(state.childCount)){
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
      let presetTabState = getTabOptions(state.childCount, state.basicData).indexOf(tabName.toUpperCase());
      dispatch({type:'set_tab_state',tabState:presetTabState})
    }
    // eslint-disable-next-line
  },[tabName, state.childCount, state.basicData])

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/datasets/${match.params.id.toLowerCase()}`
      )
      .then(async cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        if(fullResponse.reference===true){
          !state.basicData && dispatch({type:'set_show_reference_child',showReferenceChild:true})
        }
        if(fullResponse.active_flag === false){
          !state.basicData && dispatch({type:'set_show_deleted_child',showDeletedChild:true})
        }
        let codeData;
        await axiosCerebrum
          .get(
            `/api/datasets/${match.params.id.toLowerCase()}/related`,{params:{
              relationship:'REFERENCES',
              object_name:'QUERY',
              per_page:1,
              sort:"START_DESC"
            }}
          )
          .then(async r=>{
            if(r.data.items.length>0){
              await axiosCerebrum
              .get(`/api/queries/${r.data.items[0].id}`)
              .then(q=>{
                codeData = q.data
              })
              .catch(error=>{
                console.log(error)
              })
            }
          })
          .catch(error=>{
            console.log(error)
          })
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${fullResponse.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
        axiosSolr
          .get(`/solr/search/select`,{params:{q:"*",fq:`id:${fullResponse.id}`,fl:'*'}})
          .then(response=>{
            dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...(response.data.response.docs[0]||{}), ...fullResponse, source_data: sourceData, codeData }})
            dispatch({type:'set_child_count',childCount:response.data.response.docs[0]?response.data.response.docs[0].child_object_count_srt:0})
          })
          .catch(error=>{
            dispatch({ type: 'set_basic_data', basicData: fullResponse})
          })
      })
      .catch(error=>{
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }

  const followDataFetch = () => {
    axiosCerebrum
      .get(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(response=>{
        if(response.data.type==='OPT_IN')dispatch({type:'set_following',following:true});
        else{dispatch({type:'set_following',following:false})}
      })
      .catch(error=>{
        dispatch({type:'set_following',following:false})
      })
  }

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
      }
      if(msg.data.reload_issue){
        issueFetchList()
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']})
      }
      if(!isLineageLinkable)return;
      if(msg.data.open_profile_link_modal ){
        setLinkModalOpen({
          relations:['SOURCE_OF','SOURCE_FROM'],
          linkableObjects:lineageObjectsMap.DATASET
        })
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[isLineageLinkable])


  const issueFetchList = () => {
    dispatch({
      type: 'set_issue_data',
      issueLoading: true
    })
    axiosCerebrum
      .get(
        `/api/datasets/${match.params.id.toLowerCase()}/related`,
        {params: {
          relationship:'IMPACTED_BY,IMPACTED_BY_SRC',
          per_page:200,
          sort:'END_DESC'
        }} 
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type: 'set_issue_data',
            issueData: response.data
          })
          return;
        }
        axiosSolr
          .post('/solr/search/select',{query:'*',limit:response.data.items.length,filter:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`})
          .then(solrRes=>{
            dispatch({
              type: 'set_issue_data',
              issueData: {...response.data,items:solrRes.data.response.docs},
            })
          })
          .catch(error=>{
            dispatch({
              type: 'set_issue_data',
              issueData: state.issueData,
              issueError: true,
            })
          })
      })
      .catch(error=>{
        dispatch({
          type: 'set_issue_data',
          issueData: state.issueData,
          issueError: true,
        })
      })
  }

  useEffect(()=>{
    if(!state.basicData)fetchList();
    if(!state.issueData)issueFetchList();
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])

  const checkIsStewardOrOwner = () => {
    axiosCerebrum
      .get(`/api/users/${sessionData.id}/related`,{params:{
        object_id:match.params.id.toLowerCase(),
        relationship:'OWNER_OF,STEWARD_OF'
      }}) 
      .then(response=>{
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:response.data.total>0})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:false})
      })
  }

  useEffect(()=>{
    checkIsStewardOrOwner()
   // eslint-disable-next-line
  },[state.insightsData])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const data = state.basicData;

  const handleFollowClick = () => {
    if(state.following){
      axiosCerebrum
        .delete(`/api/me/follows/${match.params.id.toLowerCase()}`)
        .then(()=>{
          sendAlert({message:"Stopped following this dataset",type:'info'})
          dispatch({type:'set_following',following:false})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:"Error occurred unfollowing this dataset",type:'error'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        sendAlert({message:"Following this dataset",type:'info'})
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:"Error occurred following this dataset",type:'error'})
      })
    }
  }


  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  buttons.push(
    <DropdownButton
      iconLabel='dot_menu'
      tooltip="More actions"
      iconOnly={true}
      iconColour={theme.palette.primaryText.light}
      title={['OPEN IN APPLICATIONS']}
      optionList={[
        roles.every(r=>r==='90') || state.basicData.active_flag===false?undefined:{onClick:() => history.push(`/impact_assessment?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'ia_downstream',text:'Assess Downstream Impact',group:'OPEN IN APPLICATIONS'},
        roles.every(r=>r==='90') || state.basicData.active_flag===false?undefined:{onClick:() => history.push(`/dependency_assessment?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'ia_upstream',text:'Assess Upstream Dependencies',group:'OPEN IN APPLICATIONS'}
      ]}
    />
  )

  // buttons.push(
  //   <DropdownButton
  //     iconLabel='dot_menu'
  //     tooltip="More actions"
  //     iconOnly={true}
  //     iconColour={theme.palette.primaryText.light}
  //     title={['OPEN IN APPLICATIONS']}
  //     optionList={[
  //       {onClick:() => history.push(`/lineage_explorer?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'lineage_downstream',text:'Lineage Explorer',group:'OPEN IN APPLICATIONS'},
  //     ]}
  //   />
  // )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )

  buttons.push(
    <AddToCartButton
      object={state.basicData}
    />
  )

  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick:() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true }),iconLabel:'issue',text:'Add Issue'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
        checkTermLinkable({sessionData})?{onClick:()=>setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']}),iconLabel:'glossary',text:'Add Term'}:undefined,
        checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner})?{onClick:()=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:true}),iconLabel:'info',text:'Add Property'}:undefined,
      ]}
    />
  )

  buttons.push(
    <FollowButton
      onClick={() => handleFollowClick()}
      following={state.following}
      object={state.basicData}
    />
  )

  let bannerdisplayText,bannerCause,bannerVisibility;

  let openIssueCount = state.issueData && state.issueData.items.filter(i=>!i.issue_status_txt || !i.issue_status_txt.includes('CLOSED')).length
  if (state.issueData && openIssueCount > 0) {
    bannerdisplayText = `This dataset has ${openIssueCount} open or in progress issue(s)`;
    bannerCause = 'issue';
    bannerVisibility = 'visible';
  }

  let isShell = state.basicData.reference===true;

  if (data && isShell){
    let sourceName = data.source_data?data.source_data.name:'Unkown Source'
    if(data.reference_sources){
      if(data.reference_sources.length===0){
        bannerdisplayText=`This Dataset is referenced in unknown source. Limited metadata available.`
      }
      else if(data.reference_sources.length===1){
        bannerdisplayText=`This Dataset is referenced in ${data.reference_sources[0].reference_source.name}. ${sourceName} is not connected to K. Limited metadata available.`
      }else{
        bannerdisplayText=(
          <Typography>
            This Dataset is referenced in multiple Tools. {sourceName} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else{
      bannerdisplayText= 'This Dataset is referenced in content but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }
  
  if(data && data.active_flag === false){
    bannerdisplayText = 'This dataset has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }
  
  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this dataset has been deactivated in K. Information on this dataset is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  } 

  let tabOptions = getTabOptions(state.childCount, state.basicData);

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','note','additional_property','issue']}
        history={history}
      />
      <ProfileLayout
        header={
          <div>
            <ProfileHeader
              tabOptions={tabOptions}
              type='dataset'
              title={state.basicData.name || state.basicData.signature}
              shouldLoadBreadCrumb
              label='dataset'
              buttons={buttons}
              data={state.basicData}
              history={history}
              state={state}
              dispatch={dispatch}
              shouldLoadLinkedInstance
              bannerdisplayText={bannerdisplayText}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              onClickAddTag={()=>{
                setLinkModalOpen({
                  linkableObjects:['COLLECTION_INSTANCE'],
                  relations:['MEMBER_OF'],
                  onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                })
              }}
            />
          </div>}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={170}
            maxWidth={170}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            nodeKey={state.basicData.nodeKey}
            label={data.labels}
            tabState={state.tabState}
            fetchList={fetchList}
            data={data}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            setLinkModalOpen={setLinkModalOpen}
            isLineageLinkable={isLineageLinkable}
          />
        }
        disableMinContentHeight={['CHANGES','LINEAGE'].includes(tabOptions[state.tabState])}
        hideSideBar={['LINEAGE','CHANGES','QUALITY'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            cerebrumLabel={'datasets'}
            data={state.basicData}
            mapping={
              ['tags','description','category','sourceType','domain','verified','verifiedNot','knowledge','trustscore','lineage','upstreamSources','downstreamSources','classification','channels','owner','stewardedBy','topUsers','lastUsed','topTeams','createdBy','lastUpdated']
            }
          />
        }
        />
    </div>)
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(DatasetProfile)));